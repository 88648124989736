<!--
 * @Author: Jacob
 * @Description: 旧客户端验证码登录后 跳转至此中转页
-->
<template>
    <div />
</template>

<script lang="javascript">
import { login, getProduct } from "../api/index";
import { mapMutations } from 'vuex'
export default {
    name: "MiddleLogin",
    created() {
        this.getToken()
    },
    methods: {
        ...mapMutations('gifted', ['updateUser']),
        async getToken() {
            // localStorage.clear()
            // localStorage.removeItem("token")
            // localStorage.removeItem("user")
            try {
                const res = await login({
                    phoneNum: this.$route.params.phoneNum,
                    code: this.$route.params.code,
                })
                if (res.data.token == null) {
                    this.$message.error("获取登录认证失败！")
                    return
                }
                if (res.code === 200) {
                    localStorage.setItem("token", res.data.token);
                    this.$store.commit("setToken")
                    this.updateUser(res.data)
                    localStorage.setItem("user", JSON.stringify(res.data))

                    // 神策埋点
                    this.$sensors.bind("identity_client_user_id", res.data.userId + "")
                    this.$sensors.bind("$identity_mobile", res.data.phone + "")

                    sessionStorage.setItem("value", "client")
                    if(res.data.orderId) {
                        this.getData(res.data.orderId)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getData(order_id) {
            try {
                const res = await getProduct({ order_id })
                if(res.code === 200) {
                    switch (res.data.product_id.type) {
                        case "优才":
                            this.$router.push({
                                name: "Gifted",
                                params: {
                                    id: order_id,
                                },
                            })
                            break;
                        default:
                            break;
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    },
};
</script>

<style lang="less" scoped>
</style>
